(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

exports.default = {
    init: function init() {
        var accordion = document.querySelector('.mainnav');
        if (!accordion) return;

        var items = [].concat(_toConsumableArray(accordion.querySelectorAll('.has_children > a')));

        items.forEach(function (el) {
            el.addEventListener('click', function (link) {
                link.preventDefault();

                link.target.parentNode.classList.toggle('active');
                items.filter(function (otherLinks) {
                    return otherLinks !== link.target;
                }).forEach(function (otherLink) {
                    otherLink.parentNode.classList.remove('active');
                });
            });
        });
    }
};

},{}],2:[function(require,module,exports){
"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _accordion = require("./accordion");

var _accordion2 = _interopRequireDefault(_accordion);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

!function (e, n, t) {
    function o(e, n) {
        return (typeof e === "undefined" ? "undefined" : _typeof(e)) === n;
    }function s() {
        var e, n, t, s, a, i, r;for (var l in c) {
            if (c.hasOwnProperty(l)) {
                if (e = [], n = c[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++) {
                    e.push(n.options.aliases[t].toLowerCase());
                }for (s = o(n.fn, "function") ? n.fn() : n.fn, a = 0; a < e.length; a++) {
                    i = e[a], r = i.split("."), 1 === r.length ? Modernizr[r[0]] = s : (!Modernizr[r[0]] || Modernizr[r[0]] instanceof Boolean || (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])), Modernizr[r[0]][r[1]] = s), f.push((s ? "" : "no-") + r.join("-"));
                }
            }
        }
    }function a(e) {
        var n = u.className,
            t = Modernizr._config.classPrefix || "";if (p && (n = n.baseVal), Modernizr._config.enableJSClass) {
            var o = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");n = n.replace(o, "$1" + t + "js$2");
        }Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), p ? u.className.baseVal = n : u.className = n);
    }function i() {
        return "function" != typeof n.createElement ? n.createElement(arguments[0]) : p ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments);
    }function r() {
        var e = n.body;return e || (e = i(p ? "svg" : "body"), e.fake = !0), e;
    }function l(e, t, o, s) {
        var a,
            l,
            f,
            c,
            d = "modernizr",
            p = i("div"),
            h = r();if (parseInt(o, 10)) for (; o--;) {
            f = i("div"), f.id = s ? s[o] : d + (o + 1), p.appendChild(f);
        }return a = i("style"), a.type = "text/css", a.id = "s" + d, (h.fake ? h : p).appendChild(a), h.appendChild(p), a.styleSheet ? a.styleSheet.cssText = e : a.appendChild(n.createTextNode(e)), p.id = d, h.fake && (h.style.background = "", h.style.overflow = "hidden", c = u.style.overflow, u.style.overflow = "hidden", u.appendChild(h)), l = t(p, e), h.fake ? (h.parentNode.removeChild(h), u.style.overflow = c, u.offsetHeight) : p.parentNode.removeChild(p), !!l;
    }var f = [],
        c = [],
        d = { _version: "3.5.0", _config: { classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0 }, _q: [], on: function on(e, n) {
            var t = this;setTimeout(function () {
                n(t[e]);
            }, 0);
        }, addTest: function addTest(e, n, t) {
            c.push({ name: e, fn: n, options: t });
        }, addAsyncTest: function addAsyncTest(e) {
            c.push({ name: null, fn: e });
        } },
        Modernizr = function Modernizr() {};Modernizr.prototype = d, Modernizr = new Modernizr();var u = n.documentElement,
        p = "svg" === u.nodeName.toLowerCase(),
        h = d._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : ["", ""];d._prefixes = h;var m = d.testStyles = l;Modernizr.addTest("touchevents", function () {
        var t;if ("ontouchstart" in e || e.DocumentTouch && n instanceof DocumentTouch) t = !0;else {
            var o = ["@media (", h.join("touch-enabled),("), "heartz", ")", "{#modernizr{top:9px;position:absolute}}"].join("");m(o, function (e) {
                t = 9 === e.offsetTop;
            });
        }return t;
    }), s(), a(f), delete d.addTest, delete d.addAsyncTest;for (var v = 0; v < Modernizr._q.length; v++) {
        Modernizr._q[v]();
    }e.Modernizr = Modernizr;
}(window, document);

/* globals Modernizr */


_accordion2.default.init();

var mainnavToggle = document.querySelector('.js-mainnav-toggle');
var mainnav = document.querySelector('.mainnav');

mainnavToggle.addEventListener('click', function () {
    mainnavToggle.classList.toggle('active');
    mainnav.classList.toggle('active');
});

// Escape-Modul adaptiert von www.hexenhaus-espelkamp.de
function changeSite() {
    open('https://www.google.de', '_newtab');
    location.replace('https://www.google.de');
}

function escapeSite() {
    document.querySelector('html').style.display = 'none';
    setTimeout(changeSite, 100);
}

window.addEventListener('keydown', function (e) {
    if (e.defaultPrevented) return;
    var key = e.which || e.keyCode || 0;
    if (key === 27) escapeSite();
});

var exitButton = document.querySelector('.exit');
if (Modernizr.touchevents) {
    exitButton.style.display = 'none';
} else {
    exitButton.addEventListener('click', function () {
        escapeSite();
    });
}

},{"./accordion":1}]},{},[2]);
